@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=DM+Sans&display=swap');
body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Montserrat', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*To SVG image*/
.sizeSVG {
  width: 100px !important;
  height:  50px !important;
}

#root .MuiTypography-colorPrimary{
  color: #003B4A;
}

#root .MuiTypography-colorTextSecondary {
  color: #7A838B;
}
#root .MuiTypography-colorTextPrimary{
  color: #00C08B;
}
#root .MuiStepIcon-root.MuiStepIcon-completed,
#root .MuiStepIcon-root.MuiStepIcon-active,
#root .MuiIconButton-colorPrimary{
    color: #00c08a !important;
}
#root .MuiButton-root{
    text-transform: capitalize;
}
#root .MuiButton-root:hover{
    background: #172a33;
}
#splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #121212;
  z-index: 99999;
  pointer-events: none;
}

#splash-screen .center {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#splash-screen .logo {
  width: 128px;
  margin: 0 auto;
}

#splash-screen .logo img {
  filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))
}

#splash-screen .spinner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 100px;
}

#splash-screen .spinner-wrapper .spinner {
  position: absolute;
  overflow: hidden;
  left: 50%;
  margin-left: -50px;
  animation: outer-rotate 2.91667s linear infinite;
}

#splash-screen .spinner-wrapper .spinner .inner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#splash-screen .spinner-wrapper .spinner .inner .gap {
  position: absolute;
  left: 49px;
  right: 49px;
  top: 0;
  bottom: 0;
  border-top: 10px solid;
  box-sizing: border-box;
}

#splash-screen .spinner-wrapper .spinner .inner .left,
#splash-screen .spinner-wrapper .spinner .inner .right {
  position: absolute;
  top: 0;
  height: 100px;
  width: 50px;
  overflow: hidden;
}

#splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
#splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border: 10px solid #61DAFB;
  border-bottom-color: transparent;
  border-radius: 50%;
}

#splash-screen .spinner-wrapper .spinner .inner .left {
  left: 0;
}

#splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
  left: 0;
  border-right-color: transparent;
  animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#splash-screen .spinner-wrapper .spinner .inner .right {
  right: 0;
}

#splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  right: 0;
  border-left-color: transparent;
  animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5);
  }

  100% {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes left-wobble {

  0%,
  100% {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }
}

@keyframes right-wobble {

  0%,
  100% {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }
}

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  100% {
    transform: rotate(1080deg);
  }
}

#content {
  position:relative;
  width:250px;
  height:250px;
  display: flex;
  align-items: center; 
  justify-content: center;
  }
  
#content h2 {
  position: absolute;
  display: flex;
  padding: 10px;
}  
#content p {
  flex: 1;
  display: flex;
}
#content img {
  object-fit: cover;
  width: 90%;
  height: 75%;
}
#content:before, 
#content:after, 
#content>:last-child:before, 
#content>:last-child:after {
    position: absolute;
    width: 50px; 
    height: 50px;
    border-color: #00C08B; 
    border-style: solid;
    content: ' ';
}

#content:before {
    top: 10px;
    left: 10px;
    border-width: 1px 0 0 1px
}

#content:after {
    top: 10px;
    right: 10px;
    border-width: 1px 1px 0 0
}

#content>:last-child:before {
    bottom: 10px;
    right: 10px;     
    border-width: 0 1px 1px 0
}

#content>:last-child:after {
    bottom: 10px;
    left: 10px;
    border-width: 0 0 1px 1px
}

.numeros{
  width: 30px;
  height: 35px;
 
}
.maquetado, .maquetado-celular{
  margin: auto;
  width: 230px;
  height: 200px;
  margin-top:50px;
}
.maquetado-celular img{
  margin: auto ;
  display: block;
  padding-top: 35px;
}

.maquetado img{
    margin: auto ;
    display: block;
}

.celular{
  width: 100px;
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.chrome img {
  padding-bottom: 10px;
}

.safari{
  float: right;
  width: 100px;
  height: 100px;
}
.safari img{
      vertical-align: middle;
}
.borderGrid {
  border-left: 1px solid #E0E4E7;
}
.MuiSlider-thumb {
  color:#00C08B !important;
  border: 4px solid currentColor !important;
  background-color: transparent !important;
}
.MuiSlider-root{
  color: rgba(91, 98, 104, 0.3) !important;
  width: 65% !important;
}
.MuiSlider-rail{
  height: 4px !important;
}
.MuiSlider-track {
  height: 4px !important;
  margin-bottom: 0px !important;

}
.MuiSlider-markLabel{
  /* height: 4px !important; */
  font-size: 12px !important;
  color: #5B6268 !important;
}
.MuiSlider-marked{
  margin-bottom: 15px !important;
}

.MuiFormLabel-root.Mui-focused{
  color: #00C08B !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  color: #00C08B !important;
  border-color: #00C08B !important;
}

.MuiTypography-h5 {
  color: #666666;
}

input:-webkit-autofill, input:-webkit-autofill:hover, 
input:-webkit-autofill:focus{
  -webkit-box-shadow: 0 0 0px 1000px #D9F3EB inset;
}

.capital{
  font-size: 1rem;
  float: left;
  text-align: left;
}

.buenoParaTi{
  font-size: 1rem;   
  float: left;
  text-align: left;
}

